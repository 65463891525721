// Generated by Framer (3f65431)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { onAppear } from "https://framerusercontent.com/modules/ztHeKdDYIwNs62qRUiAl/lkdNywPv8XWYaCZyqsXP/Scrambler.js";
const RichTextOnAppear = onAppear(RichText);

const serializationHash = "framer-oVIHg"

const variantClassNames = {dV_uwQfSb: "framer-v-1e3hys7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, jNLzExfHL: click ?? props.jNLzExfHL} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jNLzExfHL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "dV_uwQfSb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapj4kd04 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (jNLzExfHL) {const res = await jNLzExfHL(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1e3hys7", className, classNames)} data-border data-framer-name={"primary"} data-highlight layoutDependency={layoutDependency} layoutId={"dV_uwQfSb"} onTap={onTapj4kd04} ref={ref ?? ref1} style={{"--border-bottom-width": "0.3px", "--border-color": "rgba(255, 255, 255, 0.6)", "--border-left-width": "0.3px", "--border-right-width": "0.3px", "--border-style": "solid", "--border-top-width": "0.3px", backgroundColor: "rgba(225, 225, 225, 0.3)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichTextOnAppear __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO05ldWV0aGluZyBTYW5zIFRlc3QgUmVndWxhcg==", "--framer-font-family": "\"Neuething Sans Test Regular\", \"Neuething Sans Test Regular Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "90%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255)))"}}>Tap here to reveal</motion.p></React.Fragment>} className={"framer-3l6riq"} fonts={["CUSTOM;Neuething Sans Test Regular"]} layoutDependency={layoutDependency} layoutId={"h1u3o37Pz"} style={{"--extracted-r6o4lv": "var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oVIHg.framer-1m9ztg3, .framer-oVIHg .framer-1m9ztg3 { display: block; }", ".framer-oVIHg.framer-1e3hys7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 20px 10px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-oVIHg .framer-3l6riq { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oVIHg.framer-1e3hys7 { gap: 0px; } .framer-oVIHg.framer-1e3hys7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-oVIHg.framer-1e3hys7 > :first-child { margin-left: 0px; } .framer-oVIHg.framer-1e3hys7 > :last-child { margin-right: 0px; } }", ".framer-oVIHg[data-border=\"true\"]::after, .framer-oVIHg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32.5
 * @framerIntrinsicWidth 159.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jNLzExfHL":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerva3DS5kAd: React.ComponentType<Props> = withCSS(Component, css, "framer-oVIHg") as typeof Component;
export default Framerva3DS5kAd;

Framerva3DS5kAd.displayName = "reveal text";

Framerva3DS5kAd.defaultProps = {height: 32.5, width: 159.5};

addPropertyControls(Framerva3DS5kAd, {jNLzExfHL: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(Framerva3DS5kAd, [{explicitInter: true, fonts: [{family: "Neuething Sans Test Regular", source: "custom", url: "https://framerusercontent.com/assets/EAocAOLRBWiibJxxznh1H6dovVs.woff2"}]}], {supportsExplicitInterCodegen: true})